import Link from 'next/link';
import Trans from '../ui/Trans';

export function AuthFormFooter() {
  return (
    <div className="flex justify-center gap-2 text-gray-500 text-xs pb-2.5">
      <Link target="_blank" href="/terms" className="hover:underline">
        <Trans i18nKey="common:termsOfUse" />
      </Link>
      |
      <Link
        target="_blank"
        href="https://www.rentgrata.com/privacy"
        className="hover:underline"
      >
        <Trans i18nKey="common:privacyPolicy" />
      </Link>
    </div>
  );
}
