'use client';
import { Toaster as Sonner } from 'sonner';

import { useMediaQuery } from '../hooks/use-media-query';

function Toaster({
  richColors = true,
  ...props
}: React.ComponentProps<typeof Sonner> = {}) {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const position = isDesktop ? 'top-right' : 'top-center';
  return (
    <Sonner
      duration={5000}
      richColors={richColors}
      position={position}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            'border flex items-center p-4 shadow-xs rounded-xl min-w-lg gap-2',
          title: 'text-sm',
          error: 'bg-error-25 border-error-500 text-error-800',
          success: 'bg-success-25 !border-success-600 text-success-800',
          warning: 'bg-warning-25 border-warning-600 text-warning-800',
          info: 'bg-primary-25 border border-primary-300 text-primary-700',
        },
      }}
      {...props}
    />
  );
}

export default Toaster;
